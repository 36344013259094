import * as React from "react";

import { MenuItem } from "../fixtures/menu"
import { HashLink as Link } from "react-router-hash-link";

export const WideMenu: React.FunctionComponent<{
    menu: MenuItem[];
  }> = ({ menu }) => (

    <div className="menu">
      <div className="items">
        {menu.map(({link, description}) =>
          <Link smooth={true} to={link} key={description}>
            <div className="item">{description}</div>
          </Link>
        )}
      </div>
    </div>
  );