import * as React from "react";

// @ts-ignore
import foto1 from "../images/1.jpg";
// @ts-ignore
import foto2 from "../images/2.jpg";
// @ts-ignore
import foto3 from "../images/3.jpg";

export const Info = () => (
  <>
    <div className="block-background">
      <div className="block" id="voorwie">
        <div className="column text-column image-column show-mobile">
          <img src={foto1} />
        </div>
        <div className="column text-column right-pad">
          <h1>Voor wie en wanneer</h1>
          <p>
            Ergotherapie is voor iedereen die als gevolg van ziekte, trauma,
            overbelasting de dagelijkse activiteiten niet meer naar tevredenheid
            kan uitvoeren. Dit kunnen simpele handelingen zijn als eten en
            aankleden, maar ook meer complexe handelingen die te maken hebben
            met revalidatie na een ongeluk of b.v. een beroerte. Bijvoorbeeld
            eten koken, lezen en schrijven, fietsen of huishoudelijk werk.
            Ergotherapie richt zich daarbij niet op de ziekte zelf, maar op de
            praktische gevolgen ervan. Het (beter en makkelijker) leren omgaan
            met de beperkingen staat centraal. Ergotherapie is niet aan leeftijd
            gebonden. De praktijken van Ergotherapie Friesland richten zich op
            (jong-) volwassenen en ouderen. Omdat de problemen zich meestal
            voordoen in de thuissituatie komt een ergotherapeut vaak aan huis.
          </p>
        </div>
        <div className="column text-column image-column show-desktop">
          <img src={foto1} />
        </div>
      </div>
    </div>

    <div className="block" id="kosten">
      <div className="column text-column image-column">
        <img src={foto2} />
      </div>
      <div className="column text-column left-pad">
        <h1>Wat doet een ergotherapeut</h1>
        <p>
          Ergotherapeuten behandelen en trainen datgene wat u belangrijk vindt
          en niet meer kan. Een ergotherapeut werkt praktisch en leert u
          gaandeweg dingen weer zelf te doen. Daarbij wordt vooral gekeken,
          samen met u, naar wat u wél kunt en wáár u dat het liefst wilt doen.
          Wat zijn de mogelijkheden in huis, om het huis of op het werk? Wat
          moet en kan er verbeteren? En welke mogelijkheden ziet u zelf? Indien
          nodig wordt overleg gevoerd met de huisarts, fysiotherapeut of de
          thuiszorg e.d. Als duidelijk is welke belemmeringen er zijn, wordt
          samen met u een oplossing gekozen en een plan gemaakt. En belangrijk
          is is dat de ergotherapeut ook samen met u zorgt dat het plan wordt
          uitgevoerd. En het contact pas wordt afgesloten als het plan naar alle
          tevredenheid is uitgevoerd.
        </p>
      </div>
    </div>
    <div className="block" id="voorwie">
      <div className="column text-column image-column show-mobile">
        <img src={foto3} />
      </div>
      <div className="column text-column right-pad">
        <h1>Verwijzing en vergoeding </h1>
        <p>
          Wanneer u een beroep wilt doen op een ergotherapeut heeft u geen
          verwijzing nodig van uw huisarts of specialist. Ergotherapie is direct
          toegankelijk, dat wil zeggen dat u rechtstreeks contact kunt opnemen
          (uitgezonderd verzekerden van de CZ groep). U bent vrij in uw keuze
          welke ergotherapeut u kiest. Vrijwel alle aangesloten praktijken
          hebben contracten met alle zorgverzekeraars. Ergotherapie is opgenomen
          in het basispakket van de zorgverzekeringswet. Iedereen heeft dan ook
          recht op maximaal tien uur per jaar. In sommige aanvullende
          verzekeringen zijn extra uren voor ergotherapie opgenomen. Wilt u
          weten of dat in uw verzekering ook het geval is, dan kunt u het beste
          contact opnemen met uw zorgverzekeraar. Houdt er rekening mee dat
          vergoedingen in de basisverzekering vallen onder het eigen risico.
        </p>
      </div>
      <div className="column text-column image-column show-desktop">
        <img src={foto3} />
      </div>
    </div>
  </>
);
