import * as React from "react";

import { Practice } from "../fixtures/practices";

// @ts-ignore
import phone from "../images/phone.svg";
// @ts-ignore
import mail from "../images/mail.svg";

function getColorMarker(color: string) {
  return `

  `;
}

export const PracticeAdress: React.FunctionComponent<{
  practice: Practice;
}> = ({ practice }) => (
  <div className="practice" key={practice.name}>
    <h2>{practice.name}</h2>
    <a href={"http://" + practice.website} className="website" target="_blank">
      {practice.website}
    </a>

    {practice.locations.map(location => (
      <div key={location.practitioner}>
        <svg className="search_pin_color" xmlns="http://www.w3.org/2000/svg" viewBox="5.014000000000002 2.015999999999998 13.969999999999999 19.968">
          <path style={{fill: location.color}} d="M 12 12 c 1.5 0 3 -1.5 3 -3 c 0 -1.5 -1.5 -3 -3 -3 c -1.5 0 -3 1.5 -3 3 c 0 1.5 1.5 3 3 3 z M 12 2.016 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z" fill="#000000"/>
        </svg>
        <h3>{location.practitioner}</h3>
      </div>
    ))}
  </div>
);

export const PracticeFull: React.FunctionComponent<{
  practice: Practice;
}> = ({ practice }) => (
  <div className={"practice " + practice.style} key={practice.name}>
    <div className="practice-header">
      <div>
        {practice.logo && <img src={practice.logo} className="practice-logo" />}
      </div>

      <div className="practice-profile">
        {practice.photo && (
          <img src={practice.photo} className="practice-photo" />
        )}
      </div>
    </div>
    <h2>{practice.name}</h2>
      <a href={"http://" + practice.website} className="website" target="_blank">
        {practice.website}
      </a>
    {practice.locations.map(location => (
      <div key={location.practitioner}>
        <h3>{location.practitioner}</h3>
        <p>
          <img src={phone} className="icon" /> {location.phoneNumber}
          <br />
          <a href={"mailto:" + location.email} className="email">
            <img src={mail} className="icon" /> {location.email}
          </a>
          <br />
          {location.address}
        </p>
      </div>
    ))}
  </div>
);
