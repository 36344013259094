import * as React from "react";
import { darken } from "polished";

import { practices, center, zipIndex, Practice } from "../fixtures/practices";
import { PracticeAdress } from "./Practice";

// @ts-ignore
if (!window.mapsPromise) {
  // @ts-ignore
  window.mapsPromise = new Promise(resolve => (window.initMap = resolve));
}

export class PracticeMap extends React.Component<
  {},
  { search: number; searchText: string }
> {
  public state = {
    search: 0,
    searchText: ""
  };

  private markers = new Map<Practice, Array<google.maps.Marker>>();
  private mapsRef = React.createRef<HTMLDivElement>();

  public setMarkersVisibility = (practices: Practice[], value: boolean) => {
    for (const practice of practices) {
      const markers = this.markers.get(practice);
      if (markers) {
        for (const marker of markers) {
          if (marker.getVisible() !== value) {
            marker.setVisible(value);
          }
        }
      }
    }
  };

  public showMarkers = (practices: Practice[]) =>
    this.setMarkersVisibility(practices, true);

  public hideMarkers = (practices: Practice[]) =>
    this.setMarkersVisibility(practices, false);

  public componentDidMount = async () => {
    // @ts-ignore
    await window.mapsPromise;

    const map = new google.maps.Map(this.mapsRef.current!, {
      center,
      zoom: 9,
      mapTypeControl: false,
      streetViewControl: false
    });

    practices.map(practice => {
      this.markers.set(practice, practice.locations.map(location => {
        const svgMarker = {
          path: "M 12 12 c 1.5 0 3 -1.5 3 -3 c 0 -1.5 -1.5 -3 -3 -3 c -1.5 0 -3 1.5 -3 3 c 0 1.5 1.5 3 3 3 z M 12 2.016 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z",
          fillColor: location.color,
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 2,
          anchor: new google.maps.Point(15, 30),
        };

        const marker = new google.maps.Marker({
          position: location.position,
          map: map,
          title: practice.name,
          icon: svgMarker,
        });

        const contentString = `
          <div>
            <h2>${practice.name}</h2>
            <a href=${"http://" + practice.website} className="website" target="_blank">
              ${practice.website}
            </a>
        
            <div>
              <h3>${location.practitioner}</h3>
            </div>
          </div>
        `;
    
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });

        marker.addListener("click", () => {
          infowindow.open({
            // @ts-ignore
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });

        return marker;
      }));
    });
  };

  public handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.currentTarget.value });
    try {
      this.setState({ search: parseInt(event.currentTarget.value) });
    } catch (e) {}
  };

  public render() {
    const selection = zipIndex[this.state.search];

    if (selection === undefined) {
      this.showMarkers(practices);
    } else {
      this.hideMarkers(practices);
      this.showMarkers(selection);
    }

    return (
      <>
        <div className="column">
          <div ref={this.mapsRef} className="map" />
        </div>
        <div className="column text-column">
          <h1>Vind een praktijk in de buurt</h1>
          <p>
            Wilt u weten welke praktijk in een bepaald gebied actief is? Vul dan
            de eerste vier cijfers van de postcode in:
          </p>
          <input
            className="search"
            type="input"
            name="search"
            value={this.state.searchText}
            placeholder="Zoek op postcode"
            onChange={this.handleSearch}
          />
          {selection ? (
            <div style={{ marginTop: 25 }}>
              <p>
                In het door u ingevoerde postcodegebied
                {selection.length === 1 ? " is " : " zijn "} de volgende
                {selection.length === 1
                  ? " ergotherapiepraktijk "
                  : " ergotherapiepraktijken "}
                actief:
              </p>
              {selection.map(practice => (
                <PracticeAdress practice={practice} />
              ))}
            </div>
          ) : this.state.search > 999 && this.state.search < 10000 ? (
            <p>
              In postcodegebied {this.state.search} is geen van de aangesloten
              praktijken actief. Neem contact op met de dichtstbijzijnde
              praktijk, dan wordt samen met u een oplossing gezocht.
            </p>
          ) : (
            <p>Voer de eerste vier cijfers van uw postcode in om te zoeken.</p>
          )}
        </div>
      </>
    );
  }
}