import * as React from "react";

function range(start: number, end: number): number[] {
  return new Array(end - start + 1).fill(0).map((d, i) => i + start);
}

const colors = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
];
let color_index = 0;

export type Location = {
  practitioner: string;
  position: { lat: number; lng: number };
  color: string;
  phoneNumber: string;
  email: string;
  address: JSX.Element | string;
  postalAddress?: JSX.Element | string;
}

export type Practice = {
  name: string;
  website?: string;
  zipcode: number[];
  locations: Array<Location>,
  photo?: string;
  logo?: string;
  style?: string;
};

export const center = { lat: 53.132867, lng: 5.803245 };
export const practices: Practice[] = [
  {
    name: "Ergopraktijk De Jong",
    website: "www.ergopraktijkdejong.nl",
    zipcode: [...new Set([...range(8900, 8941)])],
    locations: [{
      practitioner: "Nella de Jong",
      position: { lat: 53.199990, lng: 5.783820 },
      color: colors[color_index++],
      phoneNumber: "06 - 20298981",
      email: "info@ergopraktijkdejong.nl",
      address: (
        <>
          Fonteinland 7c, 8913 CZ <span className="city">Leeuwarden</span>
        </>
      ),
      postalAddress: (
        <>
          Bildtsestraat 36, 8913 EH <span className="city">Leeuwarden</span>
        </>
      ),
    }],
    // @ts-ignore
    logo: require("../images/practices/epdj.svg"),
    // @ts-ignore
    photo: require("../images/people/nella.jpg")
  },
  {
    name: "Ergotherapie Edith Weidenaar",
    website: "www.ergo-weidenaar.nl",
    zipcode: [...new Set([
      ...range(9101, 9103),
      ...range(9104, 9271),
      ...range(9231, 9232),
      ...range(9221, 9223),
      ...range(9131, 9133),
      ...range(9281, 9289),
      ...range(9291, 9298),
      ...range(9201, 9207),
      ...range(9221, 9223),
      ...range(9251, 9257),
      ...range(9801, 9845),
      ...range(9871, 9873)
    ])],
    locations: [{
      practitioner: "Edith Weidenaar",
      position: { lat: 53.247687, lng: 6.150455 },
      color: colors[color_index++],
      phoneNumber: "06 - 42474286 ",
      email: "info@ergo-weidenaar.nl",
      address: (
        <>
          Kuipersweg 74c, 9285 SW <span className="city">Buitenpost</span>
        </>
      ),
      postalAddress: (
        <>
          Balsem 2, 9285 KD <span className="city">Buitenpost</span>
        </>
      )
    }],
    // @ts-ignore
    logo: require("../images/practices/weidenaar.png"),
    // @ts-ignore
    photo: require("../images/people/edith.jpg")
  },
  {
    name: "Ergotherapie De Schakel",
    website: "www.ergotherapiedeschakel.nl",
    zipcode: [...new Set([
      ...range(8631, 8637),
      ...range(8730, 8731),
      8734,
      ...range(8800, 8897),
      ...range(8900, 8941),
      ...range(9000, 9057),
      ...range(9071, 9091),
      ...range(9110, 9112),
      ...range(9150, 9151),
      ...range(9170, 9178)
    ])],
  locations: [{
      practitioner: "Marije Sijtsma",
      position: { lat: 53.276771, lng: 5.662123 },
      color: colors[color_index++],
      phoneNumber: "06 - 39718846",
      email: "ergotherapiedeschakel@hotmail.com",
      address: (
        <>
          Van Harenstraat 110, 9076 BZ{" "}
          <span className="city">Sint Annaparochie</span>
        </>
      ),
      postalAddress: (
        <>
          Ayttawei 40, 9008 SL <span className="city">Reduzum</span>
        </>
      )
    }],
    // @ts-ignore
    logo: require("../images/practices/schakel.png"),
    // @ts-ignore
    photo: require("../images/people/marije.jpg")
  },
  {
    name: "Buro Hagelslag, ergotherapie en interieuradvies",
    website: "www.burohagelslag.nl",
    zipcode: [...new Set([
      ...range(8389, 8398),
      ...range(8400, 8459),
      ...range(8470, 8489),
      ...range(9200, 9215),
      ...range(9240, 9249),
    ])],
    locations: [{
      practitioner: "Tjitske Zuidema",
      position: { lat: 53.016966, lng: 6.097674 },
      color: colors[color_index++],
      phoneNumber: "06 - 23128403",
      email: "burohagelslag@outlook.com",
      address: (
        <>
          Feestlân 11, 8408 GK <span className="city">Lippenhuizen</span>
        </>
      ),
    }],
    // @ts-ignore
    logo: require("../images/practices/hagelslag.png"),
    // @ts-ignore
    photo: require("../images/people/tjitske.jpg")
  },
  {
    name: "Ergopraktisch",
    website: "www.ergopraktisch.nl",
    zipcode: [...new Set([
      ...range(9201,9207),
      ...range(9216,9223),
      ...range(9231,9233),
      ...range(9246,9249),
      ...range(9251,9265),
      9271,
      ...range(9281,9299 ),
      9864,
      ...range(9871,9873)
    ])],
  locations: [{
      practitioner: "Annie Kooistra",
      position: { lat: 53.180809, lng: 6.162360 },
      color: colors[color_index++],
      phoneNumber: "06 - 38965017",
      email: "info@ergopraktisch.nl",
      address: (
        <>
          De Kolk 16, 9231 CW <span className="city">Surhuisterveen</span>
        </>
      ),
    }],
    // @ts-ignore
    logo: require("../images/practices/ergopraktisch.png"),
    // @ts-ignore
    photo: require("../images/people/annie.jpg")
  },
  {
    name: "Ergo Tichtby",
    website: "www.ergotichtby.nl",
    locations: [{
      practitioner: "Rixta Spel (locatie Balk)",
      position: { lat: 52.898673, lng: 5.586557 },
      color: colors[color_index++],
      phoneNumber: "06 - 12117699",
      email: "rixta@ergotichtby.nl",
      address: <>Mr. C.J. Trompstraat 35a, 8561 BA <span className="city">Balk</span></>,
    },
    {
      practitioner: "Maartje Kooistra (locatie Akkrum)",
      position: { lat: 53.045480, lng: 5.840890 },
      color: colors[color_index++],
      phoneNumber: "06 - 22990116",
      email: "maartje@ergotichtby.nl",
      address:
        <>
          It Vegelinskampke 10, 8491 PD <span className="city">Akkrum</span>
        </>,
    }],
    zipcode: [...new Set([
      ...range(8440, 8448),
      8449,
      8451,
      8452,
      8453,
      8454,
      8455,
      8456,
      8457,
      8458,
      8459,
      8462,
      8463,
      8464,
      8465,
      8466,
      8467,
      8468,
      8469,
      8494,
      8495,
      8497,
      ...range(8500, 8503),
      8505,
      8506,
      8507,
      8508,
      8511,
      8512,
      8513,
      8514,
      8515,
      8516,
      8517,
      ...range(8520, 8521),
      8522,
      8523,
      8524,
      8525,
      8526,
      8527,
      8528,
      8529,
      ...range(8530, 8532),
      8534,
      8535,
      8536,
      8537,
      8538,
      8539,
      8541,
      8542,
      ...range(8550, 8551),
      8552,
      8553,
      8554,
      8556,
      ...range(8560, 8561),
      8563,
      8564,
      8565,
      8566,
      8567,
      8571,
      8572,
      8573,
      8574,
      8581,
      8582,
      8583,
      8584,
      ...range(8600, 8608),
      8611,
      8612,
      8613,
      8614,
      8615,
      8616,
      8617,
      8618,
      ...range(8620, 8621),
      8622,
      8623,
      8624,
      8625,
      8626,
      8627,
      8628,
      8629,
      8633,
      8647,
      ...range(8650, 8651),
      8658,
      ...range(8710, 8711),
      8713,
      8715,
      ...range(8720, 8721),
      8722,
      8723,
      8724,
      ...range(8770, 8771),
      8773,
      ...range(9000, 9001),
      9003,
      9004,
      9005,
      9006,
      9007,
      9008,
      9009,
      ...range(9010, 9011),
      9012,
      9013,
      9014,
      9087,
      9088,
      9089,
    ])],
    // @ts-ignore
    logo: require("../images/practices/tichtby.png"),
    // @ts-ignore
    photo: require("../images/people/tichtby.jpg"),
    style: "tichtby"
  },
  {
    name: "Ergotherapie Harlingen",
    website: "www.ergotherapieharlingen.nl",
    zipcode: [...new Set([
      ...range(8748, 8749),
      ...range(8751, 8853),
      ...range(8800, 8802),
      ...range(8804, 8808),
      ...range(8821, 8823),
      ...range(8853, 8857),
      ...range(8860, 8862),
      ...range(8871, 8872),
      8899
    ])],
  locations: [{
      practitioner: "Anita Kok",
      position: { lat: 53.174843677777766, lng: 5.412421764148943 },
      color: colors[color_index++],
      phoneNumber: "06 - 83056097",
      email: "info@ergotherapieharlingen.nl",
      address: (
        <>
          Havenplein 8-10, 8861XA <span className="city">Harlingen</span>
        </>
      ),
    }],
    // @ts-ignore
    logo: require("../images/practices/harlingen.png"),
    // @ts-ignore
    photo: require("../images/people/anita.jpg")
  }
];

export const zipIndex: { [key: number]: [Practice] } = {};

for (const practice of practices) {
  for (const zipcode of practice.zipcode) {
    if (zipIndex[zipcode] === undefined) {
      zipIndex[zipcode] = [practice];
    } else {
      zipIndex[zipcode].push(practice);
    }
  }
}
