import * as React from "react";

import { MenuItem } from "../fixtures/menu"
import { HashLink as Link } from "react-router-hash-link";

export const StackMenu: React.FunctionComponent<{
    menu: MenuItem[];
  }> = ({ menu }) => {
    const [active, setActive] = React.useState(false);

    return (
      <div className="stack-menu">
        <svg height="32px" version="1.1" viewBox="0 0 32 32" width="32px" className="burger" onClick={() => setActive(!active)}>
          <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/>
        </svg>

        {active && 
          <div className="items" onClick={() => setActive(!active)}>
            {menu.map(({link, description}) =>
              <Link smooth={true} to={link} key={description}>
                <div className="item">{description}</div>
              </Link>
            )}
          </div>
        }
      </div>
    );
  };