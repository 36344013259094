import * as React from "react";

import { PracticeMap } from "../components/Map";
import { practices } from "../fixtures/practices";
import { PracticeFull } from "../components/Practice";

export const Home = () => (
  <>
    <div className="banner">
      <div className="slogan">
        Netwerk van <br /> vrijgevestigde <br />
        ergotherapiepraktijken
      </div>
    </div>

    <div className="block">
      <div className="column text-column right-pad">
        <h1 id="wiezijnwij">Over ons</h1>
        <p>
          Ergotherapie Friesland is een netwerk van vrijgevestigde
          ergotherapeuten in de provincie Friesland. Ons doel is het verzorgen
          en bevorderen van kwalitatief hoogstaande ergotherapie. Als netwerk
          zijn we een informatiebron en gesprekspartner voor andere
          zorgverleners, cliënten en beleidsorganen of zorgverzekeraars.
          Daarnaast is het netwerk een platform voor het uitwisselen van kennis
          over praktijkvoering en ondernemerschap. De ergotherapiepraktijken uit
          het netwerk zijn lokaal georiënteerd en kleinschalig, maar overlappen
          qua werkgebied en streven naar provinciale dekking. Zodat ergotherapie
          voor alle inwoners in de provincie Friesland beschikbaar is en goed
          bereikbaar. Wilt u weten welke praktijk in welk gebied actief is? Vul
          dan naast het onderstaande kaartje de postcode in in het zoekveld.
        </p>
      </div>
      <div className="column text-column left-pad">
        <h1>Wat is ergotherapie</h1>
        <p>
          Door ziekte, handicap of ouderdom kunnen mensen moeite hebben met het
          uitvoeren van dagelijkse handelingen. Ergotherapie kan helpen bij het
          verminderen van de beperkingen. Een ergotherapeut richt zich op het
          (opnieuw of anders) leren uitvoeren van handelingen, al dan niet
          ondersteund met hulpmiddelen. Het werkterrein van de ergotherapeut is
          groot. Zo helpen ergotherapeuten mensen die door b.v. reuma moeite
          hebben met schrijven. Maar ook mensen met beginnende dementie worden
          door ergotherapeuten getraind om zo lang mogelijk zelfstandig te
          blijven leven. Naast behandeling en advisering kan een ergotherapeut
          ook helpen bij een plan voor woningaanpassing of het aanvragen van
          hulpmiddelen en voorzieningen bij de WMO of zorgverzekeraar.
        </p>
      </div>
    </div>

    <div className="block-background">
      <div className="block">
        <PracticeMap />
      </div>
    </div>

    <div className="block">
      <div className="column" style={{ marginTop: 25 }} id="praktijken">
        <h1>Praktijklocaties </h1>
      </div>
    </div>

    <div className="block show-desktop">
      <div className="column text-column right-pad">
        {practices.map(
          (practice, i) => i % 2 == 0 && <PracticeFull practice={practice} key={i} />
        )}
      </div>
      <div className="column text-column left-pad">
        {practices.map(
          (practice, i) => i % 2 == 1 && <PracticeFull practice={practice} key={i} />
        )}
      </div>
    </div>

    <div className="block show-mobile">
      <div className="column text-column right-pad">
        {practices.map(
          (practice, i) => <PracticeFull practice={practice} key={i} />
        )}
      </div>
    </div>
  </>
);
