export type MenuItem = {
    link: string,
    description: string
}

export const menu: MenuItem[] = [
    { link: "/#contact", description: "Contact"},
    { link: "/info#kosten", description: "Vergoeding"},
    { link: "/info#voorwie", description: "Wanneer ergotherapie"},
    { link: "/#praktijken", description: "Praktijken"},
    { link: "/#wiezijnwij", description: "Over ons"},
];
  