import * as React from "react";

export const Logo = () => (
  <svg x="0px" y="0px" viewBox="0 0 1199 345">
    <defs>
      <mask id="hole">
        <rect width="100%" height="100%" fill="white" />
        <ellipse cx="692.6" cy="199.42" rx="635.4" ry="168.5" fill="black" />
      </mask>
    </defs>
    <g>
      <g>
        <ellipse
          cx="678.4"
          cy="197.22"
          rx="635.4"
          ry="167.22"
          mask="url(#hole)"
          style={{ fill: "#5CB6EA" }}
        />
      </g>
    </g>
    <text
      transform="matrix(1 0 0 1 154.3394 225.3174)"
      style={{ fontSize: 75.4717 }}
    >
      <tspan x="0" y="0" style={{ fill: "#062466" }}>
        ERGO
      </tspan>
      <tspan x="202.79" y="0" style={{ fill: "#5CB6EA" }}>
        THERAPIE
      </tspan>
      <tspan x="551.08" y="0" style={{ fill: "#062466" }}>
        {" "}
        | FRIESLAND
      </tspan>
    </text>
  </svg>
);
